<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" v-lazy='require("@/assets/img/about/consultation/banner-bg.png")'>
      <img class="banner-center" v-lazy='require("@/assets/img/about/consultation/banner-center.png")'>
      <p class="banner-title">澜渟品牌资讯中心</p>
    </div>
    <div id="consultation-list">
      <div class="consultation-list">
        <div class="consultation-item no-show" v-for="(consultationItem, i) in consultation" :key="i"
          @click="goDetail(consultationItem.code)">
          <div class="left">
            <div class="title">{{ consultationItem.title }}</div>
            <div class="desc">{{ consultationItem.desc }}</div>
            <div class="date">{{ consultationItem.gmt_create }}</div>
          </div>
          <div class="img-content"
            :style="{'background-image': 'url('+consultationItem.cover+')'}"></div>
        </div>
      </div>
      <div @click="loadConsultation" class="load-more" v-if="this.showLoadMore">
        <span>加载更多</span>
        <img alt="" src="@/assets/img/about/consultation/down.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css'

export default {
  data() {
    return {
      consultation: [],
      pageIndex: 1,
      showLoadMore: false,
      isRequest: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.loadConsultation();
  },
  methods: {
    /**
     @param
     @return
     */
    loadConsultation(isInit) {
      if (this.isRequest) {
        return
      }
      this.isRequest = true
      this.$axios.post(`${this.$request.live}/common/pc/consultation-list`, {page_index: this.pageIndex}).then(res => {
        this.consultation = this.consultation.concat(res.data.list)
        this.isRequest = false
        this.pageIndex++
        if (res.data.is_last_page == '1') {
          this.showLoadMore = false
        } else {
          this.showLoadMore = true
        }
      })
    },

    goDetail(code) {
      this.$router.push({path: '/about/consultation/detail/' + code})
    },
  },
}
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 4.0rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-center {
    position: absolute;
    top: 1.32rem;
    left: 4.37rem;
    margin: auto;
    width: 5.26rem;
    height: 1.36rem;
  }

  > .banner-title {
    position: absolute;
    top: 1.32rem;
    left: 4.37rem;
    margin: auto;
    width: 5.26rem;
    height: 1.36rem;
    text-align: center;
    line-height: 1.36rem;
    font-size: .44rem;
    font-weight: bold;
    color: #4a384a;
  }
}

#consultation-list {
  width: 100%;
  padding: 0.4rem 0 0.8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .consultation-list {
    width: 12rem;

    > .consultation-item {
      animation: fadeInUp 0.5s;
      cursor: pointer;
      border-bottom: 0.01rem solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .left {
        width: 7.8rem;

        > .title {
          font-size: 0.26rem;
          font-weight: bolder;
          color: #333333;
          line-height: 0.4rem;
          margin-top: 0.43rem;
        }

        > .desc {
          margin: 0.08rem 0 0.2rem;
          font-size: 0.18rem;
          color: #666666;
          line-height: 0.26rem;
          height: 0.52rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        > .date {
          font-size: 0.14rem;
          color: #999999;
          line-height: 0.28rem;
          margin-bottom: 0.43rem;
        }
      }

      > .img-content {
        width: 2.59rem;
        height: 1.46rem;
        background-position: center;
        background-size: cover;
      }
    }
  }

  > .load-more {
    width: 1.2rem;
    height: 0.32rem;
    border-radius: 0.22rem;
    border: 0.01rem solid #999999;
    display: flex;
    justify-content: center;
    margin: 0.8rem 0 0 0;
    cursor: pointer;

    > span {
      color: #333;
      line-height: 0.32rem;
      margin-right: 0.04rem;
    }

    > img {
      width: 0.16rem;
    }
  }

  > .load-more:hover {
    animation: pulse 0.5s infinite;
  }
}
</style>
